import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const Demo = () => {
    const [consoleData, setConsoleData] = useState(['Loading...']);

    useEffect(() => {
        var socket = io.connect('https://18.171.200.156:8005')

        socket.on('connect', function () {
            console.log('Connected to server');
        });

        // socket.on('disconnect', () => {
        //     console.log('Disconnected from server');
        // });

        socket.on('console_data', (data) => {
            console.log('Console data received:', data);
            setConsoleData(prevData => [...prevData, data]);
        });

        // Clean up the socket connection when component unmounts
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <div>
            <h1>Arbitrage Bot</h1>
            <div id="console">
                {consoleData.map((item, index) => (
                    <p key={index}>{typeof item === 'object' ? JSON.stringify(item) : item}</p>
                ))}
            </div>
        </div>
    );
};

export default Demo;
