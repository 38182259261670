import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Copy = () => {
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);

  return (
    <div>
      <input
        value={value}
        onChange={({ target: { value } }) => {
          setValue(value);
          setCopied(false);
        }}
      />

      <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
        <span style={{ cursor: "pointer", marginRight: "10px" }}>
          Copy to clipboard with span
        </span>
      </CopyToClipboard>

      {copied ? (
        <span style={{ color: "red", marginLeft: "10px" }}>Copied.</span>
      ) : null}
    </div>
  );
};

export default Copy;
